import * as React from 'react'
import PropTypes from 'prop-types'
import Layout from './layout'

const PageLayout = ({ title, bodyClassName, children }) => {
  return (
    <Layout seoTitle={title} bodyClassName={bodyClassName}>
      <section className='hero is-light'>
        <div className='hero-body has-text-centered'>
          <h1 className='has-hero-font is-size-2 has-text-primary'>{title}</h1>
        </div>
      </section>
      <div className='container px-3 py-6'>{children}</div>
    </Layout>
  )
}

PageLayout.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default PageLayout
