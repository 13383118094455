import * as React from 'react'

import PageLayout from '../components/page-layout'
import ContactForm from '../components/common/contact-form'

const ContactPage = () => {
  return (
    <PageLayout title='Contact' bodyClassName='has-background-dark'>
      <div className='is-flex is-justify-content-center'>
        <div style={{ width: '600px' }}>
          <ContactForm />
        </div>
      </div>
    </PageLayout>
  )
}

export default ContactPage
